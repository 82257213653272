/**
 * Copyright Coqui GmbH
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import { useEffect } from 'react';

export function useRedirectToNewDomain() {
  useEffect(() => {
    const subPath = window.location.pathname + window.location.search;

    window.location.replace(process.env.GATSBY_BACKEND_URL + subPath);
  });
}
